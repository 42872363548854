import React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import "./style.css"

function ToolsAndResourcesBadge({ name, language }) {
  switch (name) {
    case "Cursos":
      return (
        <div className="tr-badge bg-blue justify-content-around">
          <StaticImage
            src={"../../assets/images/badges/icons/cursos.svg"}
            alt={"curso"}
            quality={100}
          />
          <span className="text-white">
            {" "}
            {language === "pt" ? "Curso" : "Course"}
          </span>
        </div>
      )
    case "Outros":
      return (
        <div className="tr-badge bg-grey-40 justify-content-around">
          <StaticImage
            src={"../../assets/images/badges/icons/outros.svg"}
            alt={"outros"}
            quality={100}
          />
          <span className="text-white">
            {" "}
            {language === "pt" ? "Outro" : "Other"}
          </span>
        </div>
      )
    case "Ferramentas":
      return (
        <div className="tr-badge bg-green justify-content-around">
          <StaticImage
            src={"../../assets/images/badges/icons/ferramentas.svg"}
            alt={"ferramenta"}
            quality={100}
          />
          <span className="text-white">
            {" "}
            {language === "pt" ? "Ferramenta" : "Tool"}
          </span>
        </div>
      )

    default:
      return <></>
  }
}

ToolsAndResourcesBadge.propTypes = {
  name: PropTypes.oneOf(["curso", "outros", "ferramenta"]).isRequired,
  language: PropTypes.oneOf(["pt", "en"]),
}

ToolsAndResourcesBadge.defaultProps = {
  name: "outros",
  language: "pt",
}

export default ToolsAndResourcesBadge
