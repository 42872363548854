import React from "react"
import "./styles.css"
import PropTypes from "prop-types"

const SingleCard120 = ({ children }) => {
  return (
    <div className="single-card-120 d-flex flex-column align-items-center justify-content-center">
      {children}
    </div>
  )
}

SingleCard120.propTypes = {
  children: PropTypes.node.isRequired,
}

SingleCard120.defaultProps = {}

export default SingleCard120
