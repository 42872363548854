import * as React from "react"
import GradientJumbo from "../components/gradient-jumbo"
import Layout from "../components/layout"
import Contact from "../components/sections/contact"
import ToolsAndResourceCards from "../components/sections/ferramentas-e-recursos/tools_and_resources_cards"
import Seo from "../components/seo"

const ToolsAndResourcesPage = props => (
  <Layout language="pt" pathname={props.location.pathname} hasGlobeBg={true}>
    <Seo
      title="Ferramentas & Recursos"
      lang="pt"
      description="Nesta página você encontrará ferramentas da CAMARADES"
    />
    <GradientJumbo
      subtitle="Ferramentas & Recursos"
      title="Algumas ferramentas CAMARADES"
    />
    <div className="container">
      <ToolsAndResourceCards language='pt'/>
    </div>
    <Contact language="pt" />
  </Layout>
)

export default ToolsAndResourcesPage
