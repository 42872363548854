import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { getMailTo, getProjectCitation } from "../../../utils"
import ToolsAndResourcesBadge from "../../badges/tools_resources_badge"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import SingleCard120 from "../../cards/single_card120"

const texts = {
    pt: ["Acessar", "Autores", "Citação", "Contato"],
    en: ["Acess", "Authors", "Citation", "Contact"],
}

function ToolsAndResourceCards({ language }) {
    const text = texts[language]

    const { allMarkdownRemark } = useStaticQuery(
        graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/(markdown/tools-and-resources)/" } }
          sort: {fields: frontmatter___tipo}
        ) {
          edges {
            node {
              id
              frontmatter {
                titulo
                titulo_en
                descricao
                descricao_en
                citacao
                citacao_en
                autores
                contato
                link
                tipo
              }
            }
          }
        }
      }
    `
    )

    return (
        <section className="container">
            <div className="row">
                {allMarkdownRemark.edges.map((a, i) => (
                    <section className="row d-flex justify-content-center" key={i}>
                        <div className="col-sm-12 col-md-6">
                            <div className="col-12">
                                <ToolsAndResourcesBadge language={language} name={a.node.frontmatter.tipo} />
                            </div>
                            <h3>{language === 'pt' ? a.node.frontmatter.titulo : a.node.frontmatter.titulo_en}</h3>
                            <p>
                                {language === 'pt' ? a.node.frontmatter.descricao : a.node.frontmatter.descricao_en}
                                <br />
                                <b>{text[1]}: </b>{a.node.frontmatter.autores}
                                <br />
                                <span className="font-monospace">
                                    <b>{text[2]}: </b> {language === 'pt' ? a.node.frontmatter.citacao : a.node.frontmatter.citacao_en}{". "}
                                    {getProjectCitation(language, a.node.frontmatter.link)}
                                </span>
                            </p>
                        </div>
                        <div className="col-1 d-none d-md-block"></div>
                        <div className="col row mb-3 d-flex align-content-center">
                            <div className="col">
                                <a
                                    href={a.node.frontmatter.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-decoration-none"
                                >
                                    <SingleCard120>
                                        <StaticImage
                                            src="../../../assets/images/icons/link.png"
                                            alt="link-icon"
                                        />
                                        <h4 className="pt-2 px-2 text-center text-muted fw-normal">
                                            {text[0]}
                                        </h4>
                                    </SingleCard120>
                                </a>
                            </div>
                            <div className="col">
                                <a href={getMailTo(a.node.frontmatter.contato)} className="text-decoration-none">
                                    <SingleCard120>
                                        <StaticImage
                                            src="../../../assets/images/icons/email.png"
                                            alt="email-icon"
                                        />
                                        <h4 className="pt-2 px-2 text-center text-muted fw-normal">
                                            {text[3]}
                                        </h4>
                                    </SingleCard120>
                                </a>
                            </div>
                        </div>
                        <hr />
                    </section>
                ))}
            </div>
        </section>
    )
}

ToolsAndResourceCards.propTypes = {
    language: PropTypes.oneOf(["pt", "en"]),
}

export default ToolsAndResourceCards
